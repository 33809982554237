<template>
  <FormPage
    :collectionName="collectionName"
    :tabs="tabs"
    :prevalidate="prevalidate"
    :id="$route.params.id"
    :defaultActiveTab="defaultActiveTab"
    pathPrefix="main"
    :showDeleteButton="!(isCustomer || isSuperCustomer)"
    :headerData="{ issue, lawyer }"
    v-if="isReady"
    :formIsReadOnly="isCustomer"
  >
    <template v-slot:formHeader="{ issue, lawyer }">
      <div class="vx-row" v-if="issue">
        <div class="vx-col sm:w-1/3 w-full">
          <h1>
            <strong :style="{ color: '#7367f0' }">
              {{ issue.cid }} - {{ issue.customer.alias }}
            </strong>
          </h1>
          <h6>
            {{ $moment(issue.datetime.toDate()).format("DD-MM-YYYY HH:mm") }}
          </h6>
        </div>
        <div class="vx-col sm:w-2/3 w-full text-right">
          <h6>
            <strong>{{ issue.createdBy.email }}</strong>
            <br />
            {{ $moment(issue.createdAt.toDate()).format("DD-MM-YYYY HH:mm") }}
          </h6>
        </div>
        <div class="vx-col sm:w-1/1 w-full mb-2 mt-2" v-if="lawyer">
          {{ lawyer.alias }}
          <a :href="`tel:${lawyer.phone}`" v-if="lawyer.phone">
            - <feather-icon icon="PhoneIcon" svgClasses="w-4 h-4" />
            {{ lawyer.phone }}</a
          >
          <a :href="`mailto:${lawyer.email}`" v-if="lawyer.email">
            - <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            {{ lawyer.email }}</a
          >
        </div>

        <vs-divider />
      </div>

      <vs-alert
        active="true"
        class="mt-5 mb-5"
        color="danger"
        icon-pack="feather"
        icon="icon-trash"
        v-if="issue && issue.deleted"
      >
        Este hecho no denunciable ha sido eliminado
      </vs-alert>
    </template>
    <template v-slot:witnessSearcher="{}">
      <vx-input-group>
        <v-select
          label="code"
          @search="witnessSearch"
          :filterable="false"
          :options="witnessesSearch"
          :reduce="
            (option) => ({
              id: option.objectID,
              code: option.code,
              name: option.name,
              lastname: option.lastname,
              email: option.email,
              mobile: option.mobile,
            })
          "
          :clearable="true"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="witness"
          class="mb-4 md:mb-0"
          v-validate="'required'"
          name="Witness"
          :placeholder="$t('issues.placeholders.witnesses')"
        >
          <template slot="option" slot-scope="option">
            {{ option.code }} - {{ option.name }} {{ option.lastname }} ({{
              option.email
            }})
          </template>
          <template slot="selected-option" slot-scope="option">
            {{ option.code }} - {{ option.name }} {{ option.lastname }} ({{
              option.email
            }})
          </template>
        </v-select>
        <template slot="append">
          <div class="append-text btn-addon">
            <vs-button color="primary" @click="addWitness(witness)">
              {{ $t("witnesses.buttons.add") }}
            </vs-button>
          </div>
        </template>
      </vx-input-group>
    </template>
  </FormPage>
  <div class="custom-absolute-loading" v-else>
    <div class="loading">
      <div class="effect-1 effects"></div>
      <div class="effect-2 effects"></div>
      <div class="effect-3 effects"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import firebase from "firebase/app";
import { db } from "@/firebase/firebaseConfig";

import vSelect from "vue-select";

import FormPage from "@/components/penal-retail-helpers/FormPage";
import formConfig from "@/helpers/issuesFormConfig";

import { createSearchRegexp } from "@/helpers/utils";

let collectionName = "issues";

export default {
  components: {
    vSelect,
    FormPage,
  },
  computed: {
    ...mapGetters(["allCustomers", "allEstablishments", "allLawyers"]),

    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },
    isReady() {
      return !!this.issue && !!this.files;
    },
    defaultActiveTab() {
      if (this.issue && this.issue.ticket && !(this.files || []).length) {
        return 2;
      }
      return 0;
    },
    lawyer() {
      return this.issue && this.issue.lawyer
        ? this.allLawyers.find((x) => x.id === this.issue.lawyer.id)
        : null;
    },
    issueBands() {
      const x = "Bands";
      let col = this[x.toLowerCase()];
      let iCol = this[`issues${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => iCol.some((iy) => iy.entityId === y.id));
      }
    },
    issueCriminals() {
      const x = "Criminals";
      let col = this[x.toLowerCase()];
      let iCol = this[`issues${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => iCol.some((iy) => iy.entityId === y.id));
      }
    },
    issueVehicles() {
      const x = "Vehicles";
      let col = this[x.toLowerCase()];
      let iCol = this[`issues${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => iCol.some((iy) => iy.entityId === y.id));
      }
    },
    notIssueBands() {
      const x = "Bands";
      let col = this[x.toLowerCase()];
      let iCol = this[`issues${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => !iCol.some((iy) => iy.entityId === y.id));
      }
    },
    notIssueCriminals() {
      const x = "Criminals";
      let col = this[x.toLowerCase()];
      let iCol = this[`issues${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => !iCol.some((iy) => iy.entityId === y.id));
      }
    },
    notIssueVehicles() {
      const x = "Vehicles";
      let col = this[x.toLowerCase()];
      let iCol = this[`issues${x}`];
      if (col && iCol) {
        return col
          .map((x) => ({ id: x.id, ...x }))
          .filter((y) => !iCol.some((iy) => iy.entityId === y.id));
      }
    },
  },
  watch: {
    issue() {
      if (this.issue) {
        this.$bind(
          "customerWitnesses",
          db
            .collection("witnesses")
            .where("customer.id", "==", this.issue.customer.id)
            .where("deleted", "==", false)
        );
      }
    },
  },
  created() {
    this.$bind("issue", db.collection("issues").doc(this.$route.params.id), {
      wait: true,
    });

    this.$bind(
      "witnesses",
      db
        .collection("witnesses")
        .where("issues", "array-contains", this.$route.params.id)
        .where("deleted", "==", false),
      { wait: true }
    );

    this.$bind(
      "files",
      db
        .collection("issuesFiles")
        .where("entityId", "==", this.$route.params.id)
        .where("deleted", "==", false),
      { wait: true }
    );

    ["Bands", "Criminals", "Vehicles"].forEach((x) => {
      const collectionName = x.toLowerCase();
      this.$bind(
        collectionName,
        db.collection(collectionName).where("deleted", "==", false),
        { wait: true }
      );
      this.$bind(
        `issues${x}`,
        db
          .collection(`issues${x}`)
          .where("issueId", "==", this.$route.params.id),
        { wait: true }
      );
    });
  },
  data() {
    let tabs = formConfig(this, this.$store.state.AppActiveUser.role, false);

    return {
      ...["Bands", "Criminals", "Vehicles"]
        .map((x) => {
          const collectionName = x.toLowerCase();
          return {
            [collectionName]: null,
            [`issues${x}`]: null,
          };
        })
        .reduce((a, b) => ({ ...a, ...b })),
      witness: null,
      customerWitnesses: [],
      witnesses: null,
      witnessesSearch: [],
      issue: null,
      files: null,
      collectionName,
      tabs: tabs.map((t) => ({
        name: `${collectionName}.tabs.${t.name}`,
        rows: t.rows,
      })),
    };
  },
  methods: {
    prevalidate() {
      return false;
    },

    //---------- TESTIGOS ---------------
    witnessSearch(val) {
      if (val.length > 0) {
        const list = this.customerWitnesses
          .filter((x) => !this.witnesses.some((y) => y.id === x.id))
          .map((x) => ({
            ...x,
            objectID: x.id,
            searchValue: [x.name, x.lastname, x.email, x.code]
              .filter((x) => !!x)
              .join(" "),
          }));
        this.witnessesSearch = createSearchRegexp(
          list,
          val,
          "searchValue"
        ).filter((x, i) => i < 50);

        // witnessesIndex.search(
        //   {
        //     query: val,
        //     filters: "customer.id:" + this.issue.customer.id,
        //     hitsPerPage: 50,
        //   },
        //   (err, { hits } = {}) => {
        //     if (err) throw err;
        //     this.witnessesSearch = hits;
        //   }
        // );
      } else {
        this.witnessesSearch = [];
      }
    },

    addWitness(val) {
      if (val) {
        db.collection("witnesses")
          .doc(val.id)
          .update({
            issues: firebase.firestore.FieldValue.arrayUnion(
              this.$route.params.id
            ),
          });

        this.witness = null;
      }
    },

    //---------- TESTIGOS ---------------
  },
};
</script>
