var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isReady)?_c('FormPage',{attrs:{"collectionName":_vm.collectionName,"tabs":_vm.tabs,"prevalidate":_vm.prevalidate,"id":_vm.$route.params.id,"defaultActiveTab":_vm.defaultActiveTab,"pathPrefix":"main","showDeleteButton":!(_vm.isCustomer || _vm.isSuperCustomer),"headerData":{ issue: _vm.issue, lawyer: _vm.lawyer },"formIsReadOnly":_vm.isCustomer},scopedSlots:_vm._u([{key:"formHeader",fn:function(ref){
var issue = ref.issue;
var lawyer = ref.lawyer;
return [(issue)?_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col sm:w-1/3 w-full"},[_c('h1',[_c('strong',{style:({ color: '#7367f0' })},[_vm._v(" "+_vm._s(issue.cid)+" - "+_vm._s(issue.customer.alias)+" ")])]),_c('h6',[_vm._v(" "+_vm._s(_vm.$moment(issue.datetime.toDate()).format("DD-MM-YYYY HH:mm"))+" ")])]),_c('div',{staticClass:"vx-col sm:w-2/3 w-full text-right"},[_c('h6',[_c('strong',[_vm._v(_vm._s(issue.createdBy.email))]),_c('br'),_vm._v(" "+_vm._s(_vm.$moment(issue.createdAt.toDate()).format("DD-MM-YYYY HH:mm"))+" ")])]),(lawyer)?_c('div',{staticClass:"vx-col sm:w-1/1 w-full mb-2 mt-2"},[_vm._v(" "+_vm._s(lawyer.alias)+" "),(lawyer.phone)?_c('a',{attrs:{"href":("tel:" + (lawyer.phone))}},[_vm._v(" - "),_c('feather-icon',{attrs:{"icon":"PhoneIcon","svgClasses":"w-4 h-4"}}),_vm._v(" "+_vm._s(lawyer.phone))],1):_vm._e(),(lawyer.email)?_c('a',{attrs:{"href":("mailto:" + (lawyer.email))}},[_vm._v(" - "),_c('feather-icon',{attrs:{"icon":"MailIcon","svgClasses":"w-4 h-4"}}),_vm._v(" "+_vm._s(lawyer.email))],1):_vm._e()]):_vm._e(),_c('vs-divider')],1):_vm._e(),(issue && issue.deleted)?_c('vs-alert',{staticClass:"mt-5 mb-5",attrs:{"active":"true","color":"danger","icon-pack":"feather","icon":"icon-trash"}},[_vm._v(" Este hecho no denunciable ha sido eliminado ")]):_vm._e()]}},{key:"witnessSearcher",fn:function(ref){return [_c('vx-input-group',[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mb-4 md:mb-0",attrs:{"label":"code","filterable":false,"options":_vm.witnessesSearch,"reduce":function (option) { return ({
            id: option.objectID,
            code: option.code,
            name: option.name,
            lastname: option.lastname,
            email: option.email,
            mobile: option.mobile,
          }); },"clearable":true,"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"name":"Witness","placeholder":_vm.$t('issues.placeholders.witnesses')},on:{"search":_vm.witnessSearch},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.name)+" "+_vm._s(option.lastname)+" ("+_vm._s(option.email)+") ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.name)+" "+_vm._s(option.lastname)+" ("+_vm._s(option.email)+") ")]}}],null,true),model:{value:(_vm.witness),callback:function ($$v) {_vm.witness=$$v},expression:"witness"}}),_c('template',{slot:"append"},[_c('div',{staticClass:"append-text btn-addon"},[_c('vs-button',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.addWitness(_vm.witness)}}},[_vm._v(" "+_vm._s(_vm.$t("witnesses.buttons.add"))+" ")])],1)])],2)]}}],null,false,3132758143)}):_c('div',{staticClass:"custom-absolute-loading"},[_c('div',{staticClass:"loading"},[_c('div',{staticClass:"effect-1 effects"}),_c('div',{staticClass:"effect-2 effects"}),_c('div',{staticClass:"effect-3 effects"})])])}
var staticRenderFns = []

export { render, staticRenderFns }